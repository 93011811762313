import {CTableDataCell, CTableHeaderCell, CTableRow} from "@coreui/react";
import '@coreui/coreui/dist/css/coreui.min.css'
import css from "./TabelItem.module.css"

function TableItem(props) {
    const statusClass = props.status === "active" ? css.statusActive : css.statusPause;

    return(
        <CTableRow>
            <CTableHeaderCell className={css.wrapStatus} scope="row">
                {props.affiliate_id}<div className={`${css.status} ${statusClass}`}></div>
            </CTableHeaderCell>
            <CTableDataCell>{props.name}</CTableDataCell>
            <CTableDataCell>{props.offers_count}</CTableDataCell>
        </CTableRow>
    )
}

export default TableItem;