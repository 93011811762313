import css from "./BuyyerOfferTable.module.css";
import {CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow} from "@coreui/react";
import OfferTableItem from "../../Offer/OfferTable/OfferTableItem/OfferTableItem";
import BuyyerOfferItem from "./BuyyerOfferItem/BuyyerOfferItem";

function BuyyerOfferTable(props) {
    return (
        <div className={css.tableWrap}>
            <CTable striped className={css.tableDescktop}>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">#</CTableHeaderCell>
                        <CTableHeaderCell className={css.groupSort} onClick={props.sortByName} scope="col">ГРУППА {props.sortOrder === "asc" ? "↓" : "↑"}</CTableHeaderCell>
                        <CTableHeaderCell className={css.statusCapBox} scope="col">Статус кап</CTableHeaderCell>
                        {/*<CTableHeaderCell scope="col">Type</CTableHeaderCell>*/}
                        {/*<CTableHeaderCell scope="col">К-во офферов</CTableHeaderCell>*/}
                        <CTableHeaderCell scope="col">Офферы</CTableHeaderCell>
                        {/*<CTableHeaderCell scope="col">NETWORK</CTableHeaderCell>*/}
                        <CTableHeaderCell scope="col">CAP</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody id={css.bodyTablId} className={css.tBody}>
                    {props.offerData.map((el,index)=>{
                        return <BuyyerOfferItem refreshData={props.refreshData} item={el} num={el.group_id} />
                    })}
                </CTableBody>
            </CTable>
        </div>
    )
}

export default BuyyerOfferTable