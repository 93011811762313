import {CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow} from "@coreui/react";
import '@coreui/coreui/dist/css/coreui.min.css'
import css from "./PartnerTable.module.css"
import TableItem from "./TableItem/TableItem";
import {useEffect, useState} from "react";

function PartnerTable(props) {
    const[status,setStatus] = useState("all");
    const [filtredData, setFiltredData] = useState([...props.tableData])


    const onChangeSelect = (e) => {
        switch (e.target.value){
            case "all":
                setStatus("all")
                break
            case "active":
                setStatus("active")
                break
            case "pause":
                setStatus("pause")
                break
            default:
                break
        }
    }

    useEffect(()=>{
        switch (status){
            case "all":
                setFiltredData([...props.tableData]);
                break
            case "active":
                const activeData = props.tableData.filter(item => item.state === "active");
                setFiltredData(activeData);
                break
            case "pause":
                const pauseData = props.tableData.filter(item => item.state === "pause");
                setFiltredData(pauseData);
                break
            default:
                break
        }
    },[status])

    return(
        <div className={css.tableSection}>
            <form onChange={onChangeSelect}>
                <select id="status" className={css.selectFilter}>
                    <option className={css.selectItem} value="all">Все</option>
                    <option className={css.selectItem} value="active">Активные</option>
                    <option className={css.selectItem} value="pause">Пауза</option>
                </select>
            </form>
            <CTable striped>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">#id</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Название</CTableHeaderCell>
                        <CTableHeaderCell scope="col">К-во офферов</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {
                        filtredData?.map((el,index)=>{
                        return    <TableItem status={el.state} name={el.name} offers_count={el.offers_count}  affiliate_id={el.affiliate_id} />
                        })
                    }
                </CTableBody>
            </CTable>
        </div>
    )
}

export default PartnerTable;