import {CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow} from "@coreui/react";
import TableItem from "../../PartnerNetwork/PartnerTable/TableItem/TableItem";
import {useState} from "react";
import {nanoid} from "nanoid";
import OfferTableItem from "./OfferTableItem/OfferTableItem";
import css from "./OfferTable.module.css"
import AZ from "../../../img/A-Z.png";
import ZA from "../../../img/Z-A.png";


function OfferTable(props) {



    return(
        <div className={css.tableWrap}>
            <CTable striped className={css.tableDescktop}>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">#</CTableHeaderCell>
                        <CTableHeaderCell className={css.groupSort} onClick={props.sortByName} scope="col">ГРУППА {props.sortOrder === "asc" ? "↓" : "↑"}</CTableHeaderCell>
                        <CTableHeaderCell className={css.statusCapBox} scope="col">Статус кап</CTableHeaderCell>
                        {/*<CTableHeaderCell scope="col">Type</CTableHeaderCell>*/}
                        {/*<CTableHeaderCell scope="col">К-во офферов</CTableHeaderCell>*/}
                        <CTableHeaderCell scope="col">Офферы</CTableHeaderCell>
                        {/*<CTableHeaderCell scope="col">NETWORK</CTableHeaderCell>*/}
                        <CTableHeaderCell scope="col">CAP</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody id={css.bodyTablId} className={css.tBody}>
                    {props.offerData.map((el,index)=>{
                        return <OfferTableItem refreshData={props.refreshData} item={el} num={el.group_id} />
                    })}
                </CTableBody>
            </CTable>
        </div>
    )
}


export default OfferTable