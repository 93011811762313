import Container from "../Container/Container";
import refresh from "../../img/refresh.png"
import css from "./ManualRefresh.module.css"
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {useState} from "react";
import host from "../../config/config";

function ManualRefresh  (){
    const [className,setClassName] = useState("")

    const refreshUrl = `${host}api/v1/keitaro/manual_update/`

    const token = localStorage.getItem('accessTokenLocal');

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            console.log(res.data)
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            console.log(e)
        })
    }

    const onClickRefresh = ()=>{
        setClassName(css.Spin)
        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };
        axios.get(refreshUrl,config)
            .then((response) => {
                const refresh = response.data;
               setClassName(css.stopSpin)
                NotificationManager.success("Данные успешно обновлены","",3000)
            })
            .catch((error) => {
                refreshTokenData()
                NotificationManager.warning('Проблемы обновления данных', 'Попробуйте позже', 3000);
            });
    }

  return(
      <Container>
              <img onClick={onClickRefresh} className={`${css.iconRefresh} ${className}`} src={refresh} alt="refresh icon" width={50}/>
      </Container>
  )
}

export default ManualRefresh