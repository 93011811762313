import React, { useEffect, useState } from "react";
import axios from "axios";
import css from "./BuyersBy.module.css";
import { NotificationManager } from "react-notifications";
import { MdDeleteForever } from "react-icons/md";
import host from "../../../../../config/config";
function BuyersBy(props) {
    const [offersBuyers, setOffersBuyers] = useState([]);
    const [buyerList, setBuyerList] = useState([]);
    const [selectedBuyers, setSelectedBuyers] = useState([]);

    const token = localStorage.getItem("accessTokenLocal");

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`;

        const refreshToken = localStorage.getItem("refreshTokenLocal");
        const tokenData = {
            refresh: refreshToken,
        };

        axios
            .post(refreshTokenUrl, tokenData)
            .then((res) => {
                console.log(res.data);
                localStorage.setItem("accessTokenLocal", `JWT ${res.data.access}`);
                localStorage.setItem("refreshTokenLocal", res.data.refresh);
                window.location.reload(false);
            })
            .catch((e) => {
                NotificationManager.warning(
                    "Проблемы с входом",
                    "Авторизируйтесь в аккаунт",
                    3000
                );
                localStorage.setItem("accessTokenLocal", "");
                localStorage.setItem("refreshTokenLocal", "");
                NotificationManager.warning(
                    "Проблемы с входом",
                    "Авторизируйтесь в аккаунт",
                    3000
                );
                console.log(e);
            });
    };

    const getBuyersList = () => {
        const buyersUrl = `${host}api/v1/user/buyers`;

        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };

        axios
            .get(buyersUrl, config)
            .then((res) => {
                console.log(res.data);
                setBuyerList([...res.data]);
            })
            .catch((err) => {
                refreshTokenData();
                console.log(err);
            });
    };

    const getOfferBuyers = () => {
        const buyersUrl = `${host}api/v1/offers_group/${props.item.group_id}/buyers/`;

        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };

        axios
            .get(buyersUrl, config)
            .then((res) => {
                console.log(res.data);
                setOffersBuyers([...res.data]);
            })
            .catch((err) => {
                refreshTokenData();
                console.log(err);
            });
    };







    useEffect(() => {
        getOfferBuyers();
        getBuyersList();
    }, []);

    return (
        <div className={css.buyersModalSection}>
            <div className={css.headTitle}>
                <b>Keytaro login</b>
                <b>Telegram username</b>
            </div>
            <ul className={css.buyersList}>
                {offersBuyers.map((el, index) => (
                    <li className={css.buyersItem} key={index}>
                        <span>{el.keitaro_login}</span>
                        <span>{el.telegram_username}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BuyersBy;
