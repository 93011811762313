import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Statistics from "./Components/Statistics/Statistics";
import Layout from "./Components/Layout/Layout";
import Offer from "./Components/Offer/Offer";
import PartnerNetwork from "./Components/PartnerNetwork/PartnerNetwork";
import {useEffect, useState} from "react";
import axios from "axios";
import BuyyerOffer from "./Components/BuyyerOffer/BuyyerOffer";
import MyOffers from "./Components/MyOffers/MyOffers";
import host from "./config/config";

function App() {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("")
    const [accessToken, setAccessToken] = useState("")
    const [showLogin, setShowLogin] = useState(false)

    const onExit = () =>{
        localStorage.setItem('accessTokenLocal',"")
        localStorage.setItem('refreshTokenLocal',"")
        localStorage.setItem('userRole',"")
        setShowLogin(false)
    }

    const onChangeForm = (e) =>{
        switch (e.target.name){
            case "login":
                setUserName(e.target.value);
                break
            case "password":
                setPassword(e.target.value);
                break
            default:
                break
        }
    }

    useEffect(()=>{
        // const accessTok = 'http://176.223.128.126:8008/api/refresh/';
        // const token = localStorage.getItem('refreshTokenLocal');
        // const config = {
        //     headers: {
        //         'Authorization': `${token}`
        //     }
        // };
        //
        // axios.post(accessTok, null, config)
        //     .then((res) => {
        //         console.log(res.data);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        const token = localStorage.getItem('accessTokenLocal');

        if(token){
            setShowLogin(true)
        }else{
            setShowLogin(false)
        }


    },[])
    const onSubmitLogin = () => {



            const getToken = `${host}api/token/`

            const authData = {
                username : userName,
                password : password
            }

            axios.post(getToken,authData).then((res)=>{
                setAccessToken(`JWT ${res.data.access}`)
                localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
                localStorage.setItem('userRole',`${res.data.user_role}`)
                localStorage.setItem('refreshTokenLocal',`${res.data.refresh}`)
                console.log(`JWT ${res.data.access}`)
                setShowLogin(true)
            }).then(() => {
                window.location.reload()
        }).catch(err=>{
                console.log(err)
            })

            setUserName("")
             setPassword("")
        }


    // const getToken = 'http://176.223.128.126:8008/api/token/'
    // const refreshToken = 'http://176.223.128.126:8008/api/token/refresh/'
    //
    // useEffect(()=>{
    //     axios.get(refreshToken).then(response=>{
    //             console.log(response)
    //         }
    //     ).catch(err=>{
    //         console.log(err)
    //     })
    //     const authData = {
    //         username : "admin",
    //         password : "admin123456"
    //     }
    //
    //     axios.post(getToken,authData).then((res)=>{
    //         console.log("Auth")
    //     }).catch(err=>{
    //         console.log(err)
    //     })
    // },[])
  return (
    <div className="App">
        <Routes>
             <Route path="*" element={<Layout onExit={onExit} showLogin={showLogin} userName={userName} password={password} onSubmitLogin={onSubmitLogin} onChangeForm={onChangeForm}/>}>
                 {/*<Route path="statistics" element={<Statistics/>}></Route>*/}
                 <Route index element={<Offer/>}></Route>
                 <Route path="partnerNetwork" element={<PartnerNetwork/>}></Route>
                 <Route path="buyerOffers" element={<BuyyerOffer/>}></Route>
                 <Route path="myOffers" element={<MyOffers/>}></Route>
             </Route>
        </Routes>
    </div>
  );
}

export default App;
