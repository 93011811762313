import { TbMessageCirclePlus } from "react-icons/tb";
import css from "./Feedbacks.module.css"
import {useState} from "react";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import host from "../../../../../config/config";
function Feedbacks(props) {
    const [showAddFeedBack,setShowAddFeedback] = useState(false)
    const [feedBack, setFeedBack] = useState('')

    const token = localStorage.getItem('accessTokenLocal');
    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            console.log(res.data)
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            console.log(e)
        })
    }
    const toggleAddFeedback = () => {
        setShowAddFeedback(!showAddFeedBack)
    }

    const handleOnChangeFeedback = (e) =>{
        setFeedBack(e.target.value)
    }

    const handleSubmitFeedback = (e) =>{
        e.preventDefault()
        const feedbackUrl = `${host}api/v1/buyer/offer_groups/${props.item.group_id}/feedback/`;
        const feedbackData = {
            feedback: feedBack
        } ;

        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };

        axios.put(feedbackUrl,feedbackData,config).then((res)=>{
            props.refreshData()
            console.log("AddComment changed")
        }).catch(err=>{
            refreshTokenData()
            console.log(err)
        })
    }

    const removeFeedback = () =>{
        const feedbackUrl = `${host}api/v1/buyer/offer_groups/${props.item.group_id}/feedback/`;

        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };

        axios.delete(feedbackUrl,config).then((res)=>{
            props.refreshData()
            console.log("RemoveComment")
        }).catch(err=>{
            refreshTokenData()
            console.log(err)
        })
    }

    return(
        <>
            {props.item.feedbacks.length !== 0 ?<ul className={css.commentList}>
                {props.item.feedbacks.map((el,index)=>{
                   return <li key={index}>
                       <h4>{el.buyer_login}</h4>
                       <div>{el.feedback}</div>
                   </li>
            })
                }</ul>:"Отзывов нет"}
        </>
    )
}

export default Feedbacks