import React, { useEffect, useState } from "react";
import axios from "axios";
import css from "./Buyyers.module.css";
import { NotificationManager } from "react-notifications";
import { MdDeleteForever } from "react-icons/md";
import host from "../../../../../config/config";
function BuyersUpd(props) {
    const [offersBuyers, setOffersBuyers] = useState([]);
    const [buyerList, setBuyerList] = useState([]);
    const [selectedBuyers, setSelectedBuyers] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const token = localStorage.getItem("accessTokenLocal");

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`;

        const refreshToken = localStorage.getItem("refreshTokenLocal");
        const tokenData = {
            refresh: refreshToken,
        };

        axios
            .post(refreshTokenUrl, tokenData)
            .then((res) => {
                console.log(res.data);
                localStorage.setItem("accessTokenLocal", `JWT ${res.data.access}`);
                localStorage.setItem("refreshTokenLocal", res.data.refresh);
                window.location.reload(false);
            })
            .catch((e) => {
                NotificationManager.warning(
                    "Проблемы с входом",
                    "Авторизируйтесь в аккаунт",
                    3000
                );
                localStorage.setItem("accessTokenLocal", "");
                localStorage.setItem("refreshTokenLocal", "");
                NotificationManager.warning(
                    "Проблемы с входом",
                    "Авторизируйтесь в аккаунт",
                    3000
                );
                console.log(e);
            });
    };

    const getBuyersList = () => {
        const buyersUrl = `${host}api/v1/user/buyers`;

        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };

        axios
            .get(buyersUrl, config)
            .then((res) => {
                setBuyerList([...res.data]);
            })
            .catch((err) => {
                refreshTokenData();
                console.log(err);
            });
    };

    const getOfferBuyers = () => {
        const buyersUrl = `${host}api/v1/offers_group/${props.item.group_id}/buyers/`;

        const config = {
            headers: {
                Authorization: `${token}`,
            },
        };

        axios
            .get(buyersUrl, config)
            .then((res) => {
                setOffersBuyers([...res.data]);
            })
            .catch((err) => {
                refreshTokenData();
                console.log(err);
            });
    };

    const deleteAllBuyers = ()=>{
        const removeBuyerUrl = `${host}api/v1/offers_group/${props.item.group_id}/buyers/`;

        axios
            .delete(removeBuyerUrl, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            .then((res) => {
                getOfferBuyers(); // Refresh the list of offer buyers after removal
            })
            .catch((err) => {
                debugger
                refreshTokenData();
                console.error("Error removing buyer", err);
            });
    }

    const handleAddBuyers = () => {
        const data = {
            telegram_ids: selectedCheckboxes,
        };

        const addBuyersUrl = `${host}api/v1/offers_group/${props.item.group_id}/buyers/`;

        axios
            .post(addBuyersUrl, data, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            .then((res) => {
                getOfferBuyers(); // Оновити список покупців пропозицій після додавання
            })
            .catch((err) => {
                refreshTokenData();
                console.error("Помилка додавання покупців", err);
            });
    };

    const handleRemoveBuyer = (telegramId) => {
        const removeBuyerUrl = `${host}api/v1/offers_group/${props.item.group_id}/buyers/${Number(telegramId)}/`;


        axios
            .delete(removeBuyerUrl, {
                headers: {
                    Authorization: `${token}`,
                },
            })
            .then((res) => {
                getOfferBuyers();// Refresh the list of offer buyers after removal
            })
            .catch((err) => {
                refreshTokenData();
                console.error("Error removing buyer", err);
            });
    };

    const handleCheckboxChange = (e, telegramId) => {
        const isChecked = e.target.checked;

        setSelectedCheckboxes((prevSelected) =>
            isChecked
                ? [...prevSelected, telegramId]
                : prevSelected.filter((id) => id !== telegramId)
        );
    };

    useEffect(() => {
        getOfferBuyers();
        getBuyersList();
    }, []);

    return (
        <div className={css.buyersModalSection}>
            <div className={css.headTitle}>
                <b>Keytaro login</b>
                <b>Telegram username</b>
            </div>
            <ul className={css.buyersList}>
                {offersBuyers.map((el, index) => (
                    <li className={css.buyersItem} key={index}>
                        <span>{el.keitaro_login}</span>
                        <span>{el.telegram_username}</span>
                        <MdDeleteForever className={css.deleteIcon} onClick={() => handleRemoveBuyer(el.telegram_id)} />
                    </li>
                ))}
            </ul>
            <div className={css.selectedWrap}>
                <div className={css.checkBoxWrap}>
                    {buyerList.map((buyer) => (
                        <label key={buyer.telegram_id} className={css.checkboxContainer}>
                            {buyer.keitaro_login} - {buyer.telegram_username}
                            <input
                                type="checkbox"
                                value={buyer.telegram_id}
                                checked={selectedCheckboxes.includes(buyer.telegram_id)}
                                onChange={(e) => handleCheckboxChange(e, buyer.telegram_id)}
                            />
                            <span className={css.checkmark}></span>
                        </label>
                    ))}
                </div>
                <button className={css.btn} onClick={handleAddBuyers}>Підтвердити</button>
                <button className={css.btn} onClick={deleteAllBuyers}>Очистити всіх</button>
            </div>
        </div>
    );
}

export default BuyersUpd;
