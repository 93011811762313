import {useEffect, useState} from "react";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import css from "./MyOffersItem.module.css";
import {
    CButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle, CTable, CTableBody,
    CTableDataCell, CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";
import {FaNotesMedical} from "react-icons/fa";
import {BsFillPeopleFill} from "react-icons/bs";
import OfferItemsOffers from "../../../Offer/OfferTable/OfferTableItem/OfferItemOffers/OfferItemsOffers";
import CapModal from "../../../Offer/OfferTable/OfferTableItem/CapModal/CapModal";
import BuyersUpd from "../../../Offer/OfferTable/OfferTableItem/Buyyers/BuyersUpd";
import BuyersBy from "./BuyersBy/BuyersBy";
import MyOffersItemOffers from "./MyOffersItemOffers/MyOffersItemOffers";
import { FaComments } from "react-icons/fa6";
import Feedbacks from "./Feedbacks/Feedbacks";
import host from "../../../../config/config";

function MyOffersItem(props) {
    const [visibleMd, setVisibleMd] = useState(false)
    const [visibleSm, setVisibleSm] = useState(false)
    const [visibleCap, setVisibleCap] = useState(false)
    const [visibleNotes, setVisibleNotes] = useState(false)
    const [visibleStatus, setVisibleStatus] = useState(false)
    const [visibleBuyers, setVisibleBuyers] = useState(false)
    const [visibleComments, setVisibleComments] = useState(false)
    const [status,setStatus] = useState("active")
    const [notification, setNotification] = useState(false)
    const [notes,setNotes] = useState("")


    useEffect(()=>{
        setNotes(props.item.notes)
    },[])

    const token = localStorage.getItem('accessTokenLocal');

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            console.log(res.data)
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            console.log(e)
        })
    }

    let statusClass ;
    if(props.item.state === "active"){
        statusClass = css.statusActive
    }else if(props.item.state ==="pause"){
        statusClass = css.statusPause
    }else{
        statusClass = css.endCap
    }

    let statusNetwork ;

    try {
        if(props.item?.affiliate_network.state === "active"){
            statusNetwork = css.statusActive
        }else if(props.item?.affiliate_network.state ==="pause"){
            statusNetwork = css.statusPause
        }else{
            statusNetwork = css.endCap
        }
    }catch(e){

    }

    const onClickCapType = (e) =>{
        console.log(e.target.name)
        setVisibleCap(!visibleCap)
    }

    const onChangeStatus = (e) => {
        setStatus(e.target.value)
    }

    const onSubmitStatus = (e) => {
        e.preventDefault()

        const statusUrl = `${host}api/v1/offers_group/${props.item.group_id}/change_status/`;
        const statusData = {
            status,
        } ;

        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };

        axios.put(statusUrl,statusData,config).then((res)=>{
            props.refreshData()
            setVisibleStatus(!visibleStatus)
            console.log("Status changed")
        }).catch(err=>{
            refreshTokenData()
            console.log(err)
        })

    }

    const onChangeNotes = (e) =>{
        setNotes(e.target.value)
    }
    const onSubmitNotes = (e) => {
        e.preventDefault()
        const notesUrl = `${host}api/v1/offers_group/${props.item.group_id}`;
        const notesData = {
            notes
        } ;

        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };

        axios.put(notesUrl,notesData,config).then((res)=>{
            props.refreshData()
            setVisibleNotes(!visibleNotes)
            console.log("Notes changed")
        }).catch(err=>{
            refreshTokenData()
            console.log(err)
        })
    }

    const onChangeCheckbox = (e) => {
        const isChecked = e.currentTarget.checked;
        setNotification(isChecked)
    }

    const changeVisibleCap = () =>{
        setVisibleCap(false)
    }

    return(<>
            <CTableRow>
                <CTableHeaderCell className={css.StatusWrap} scope="row">{props.num}
                    {/*<div className={`${css.Status} ${statusClass}`}></div>*/}
                </CTableHeaderCell>
                <CTableDataCell>{props.item.name}<FaNotesMedical title={"Заметки"} className={css.notesIcon} onClick={() => setVisibleNotes(!visibleNotes)}/>
                    <BsFillPeopleFill title={"Беера"} className={css.buyersIcon} onClick={() => setVisibleBuyers(!visibleBuyers)}/>
                    <FaComments title={"Отзывы"} className={css.commentsIcon}  onClick={() => setVisibleComments(!visibleComments)}/>
                </CTableDataCell>
                <CTableDataCell className={css.capSizeBox}>[{props.item.total_conversions}/{props.item.cap? props.item.cap.size: "-"}]</CTableDataCell>
                {/*<CTableDataCell>{props.item.cap? props.item.cap.type.name:"-"}</CTableDataCell>*/}
                {/*<CTableDataCell>{props.item.offers_count}</CTableDataCell>*/}
                <CTableDataCell>{props.item.offers.length == 0 ? "-" :
                    <CButton className={`${css.btn} ${css.btnBlue}`}  onClick={() => setVisibleMd(!visibleMd)}>{props.item.offers_count}</CButton>
                }</CTableDataCell>
                {/*{ props.item.affiliate_network ? <CTableDataCell><CButton className={`${css.btn} ${css.btnBlue}`} onClick={() => setVisibleSm(!visibleSm)}>Инфо</CButton></CTableDataCell>:<CTableDataCell> - </CTableDataCell>}*/}
                <CTableDataCell >{props.item.cap ? <p>{props.item.cap.type.name}</p> :
                    <p>-</p>
                }</CTableDataCell>
                <CTableDataCell><div className={`${css.btn} ${css.statusBg} ${css.btnBlue} ${statusClass}`}>{props.item.state}</div></CTableDataCell>
            </CTableRow>
            <CModal
                fullscreen
                visible={visibleMd}
                onClose={() => setVisibleMd(false)}
                aria-labelledby="FullscreenExample3"
            >
                <CModalHeader>
                    <CModalTitle id="FullscreenExample3">Офферы группы #{props.num}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CTable striped>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">Оффер</CTableHeaderCell>
                                <CTableHeaderCell scope="col">id Оффера</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {props.item.offers.map((el,index)=>{
                                return <MyOffersItemOffers item={el}  />
                            })}
                        </CTableBody>
                    </CTable>
                </CModalBody>
            </CModal>
            <CModal
                fullscreen="sm"
                visible={visibleSm}
                onClose={() => setVisibleSm(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle id="FullscreenExample2">Информация о партнёрской сети</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className={css.networkStatusWrap}>
                        <div className={`${css.networkStatus} ${statusNetwork}`}></div>
                        <ul className={css.listEl}>
                            <li className={css.listItem}><span>Аффилированный id:</span> <span>{props.item.affiliate_network?.affiliate_id }</span></li>
                            <li className={css.listItem}><span>Имя:</span> <span>{props.item.affiliate_network?.name}</span></li>
                            {/*<li className={css.listItem}><span>К-во офферов:</span> <span>{props.item.affiliate_network?.offers_count}</span></li>*/}
                        </ul>
                    </div>
                </CModalBody>
            </CModal>
            <CModal
                fullscreen="sm"
                visible={visibleCap}
                onClose={() => setVisibleCap(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle className={css.capHeader} id="FullscreenExample2">
                        <span>CAP</span>
                        <label className={css.checkboxWrap}>
                            <input  onChange={onChangeCheckbox} type="checkbox"  className={css.checkboxInput}/>
                            <span className={css.checkboxTitle}>Отправлять уведомления всем</span>
                        </label>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CapModal item={props.item}  notification={notification} changeVisibleCap={changeVisibleCap} refreshData={props.refreshData}  />
                </CModalBody>
            </CModal>

            <CModal
                fullscreen="sm"
                visible={visibleStatus}
                onClose={() => setVisibleStatus(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle id="FullscreenExample2">Изменить статус</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form onChange={onChangeStatus} onSubmit={onSubmitStatus} className={css.statusSelect}>
                        <select className={css.selectorList} name="statusSelect" id="statusSelector">
                            <option value="active">active</option>
                            <option value="pause">pause</option>
                        </select>
                        <button className={css.btnSelectStatus} type="submit">Обновить статус</button>
                    </form>
                </CModalBody>
            </CModal>
            <CModal
                fullscreen="sm"
                visible={visibleStatus}
                onClose={() => setVisibleStatus(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle id="FullscreenExample2">Изменить статус</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form onChange={onChangeStatus} onSubmit={onSubmitStatus} className={css.statusSelect}>
                        <select className={css.selectorList} name="statusSelect" id="statusSelector">
                            <option value="active">active</option>
                            <option value="pause">pause</option>
                        </select>
                        <button className={css.btnSelectStatus} type="submit">Обновить статус</button>
                    </form>
                </CModalBody>
            </CModal>

            <CModal
                fullscreen="sm"
                visible={visibleNotes}
                onClose={() => setVisibleNotes(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle className={css.capHeader} id="FullscreenExample2">
                        <span>Notes</span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                        <div>{notes || props.item.notes || "Заметок нет"}</div>
                </CModalBody>
            </CModal>

            <CModal
                fullscreen="sm"
                visible={visibleBuyers}
                onClose={() => setVisibleBuyers(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle className={css.capHeader} id="FullscreenExample2">
                        <span>Buyers</span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <BuyersBy item={props.item} />
                </CModalBody>
            </CModal>

            <CModal
                fullscreen="sm"
                visible={visibleComments}
                onClose={() => setVisibleComments(false)}
                aria-labelledby="FullscreenExample2"
            >
                <CModalHeader>
                    <CModalTitle className={css.capHeader} id="FullscreenExample2">
                        <span>Комментарии</span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                   <Feedbacks item={props.item} />
                </CModalBody>
            </CModal>
        </>
    )
}

export default MyOffersItem