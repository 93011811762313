import {CButton, CTableDataCell, CTableHeaderCell, CTableRow} from "@coreui/react";
import css from "./OffersItemsOffers.module.css"

function OfferItemsOffers(props) {

    let statusClass ;
    if(props.item.state === "active"){
        statusClass = css.statusActive
    }else if(props.item.state ==="pause"){
        statusClass = css.statusPause
    }else{
        statusClass = css.endCap
    }

    return(
        <CTableRow>
            <CTableDataCell className={css.StatusWrap}>{props.item.name} <div className={`${css.Status} ${statusClass}`}></div></CTableDataCell>
            <CTableDataCell>{props.item.offer_id}</CTableDataCell>
        </CTableRow>
    )
}

export default OfferItemsOffers;