import Container from "../Container/Container";
import css from "./PartnerNetwork.module.css"
import  CIcon  from '@coreui/icons-react';
import {cilChartLine} from '@coreui/icons';
import PartnerTable from "./PartnerTable/PartnerTable";
import {useEffect, useState} from "react";
import {nanoid} from "nanoid";
import PartnerFrom from "./PartnerForm/PartnerFrom";
import axios from "axios";
import LoaderIcon from "react-loader-icon";
import OfferTable from "../Offer/OfferTable/OfferTable";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {useNavigate} from "react-router-dom";
import host from "../../config/config";
function PartnerNetwork() {
    const [showForm, setShowForm] = useState(false)
    const [showLoader,setShowLoader] = useState(true)
    const[tableData,setTableData] = useState([
        {
            affiliate_id:0,
            name:"",
            offers_count:"",
            state:""
        }
    ])
    const navigate = useNavigate()

    const apiUrl = `${host}api/v1/affiliate_networks/`;

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            console.log(res.data)
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            console.log(e)
        })
    }

    useEffect(()=>{
        if(localStorage.getItem('userRole') !== 'head_of_media_buying'){
            navigate('/myOffers')
        }
        const token = localStorage.getItem('accessTokenLocal');

            const config = {
                headers: {
                    'Authorization': `${token}`
                }
            };

        axios.get(apiUrl,config)
            .then((response) => {
                const affiliateNetworks = response.data;
                setTableData([...affiliateNetworks])
                setShowLoader(false)
            })
            .catch((error) => {
                refreshTokenData()
                NotificationManager.warning('Проблемы с сервером', 'Попробуйте позже', 3000);
            });
    },[])



    const sortCompanyByName = (a,b) =>{
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    const partnerTableData = tableData.sort(sortCompanyByName)

   //  const changeShowForm = ()=>{
   //      setShowForm(show => !show)
   //  }
   //
   // const addNewOffer = (offer) => {
   //     setTableData(data => {
   //       return [
   //            ...data,
   //            offer
   //        ]
   //     })
   //  }

    return(
        <div className={css.partnersSection}>
            <Container>
                <div className={css.partnersTopWrap}>
                    {
                      !showForm && <div className={css.partnersTitle}>
                            <h2 className={css.title}>Партнерские сети</h2>
                            <p className={css.subTitle}>Список моих партнерских сетей</p>
                        </div>
                    }
                    {/*{ !showForm && <button onClick={changeShowForm} className={css.addPartnerBtn}>*/}
                    {/*    <CIcon icon={cilChartLine} width="15" style={{'--ci-primary-color': 'white'}} />*/}
                    {/*    <span className={css.btnTitle}>Добавить партнерскую сеть</span>*/}
                    {/*</button>}*/}
                    {/*<buuton className={css.addPartnerBtn}> <span className={css.btnTitle}>{ sortData?"Сортировать по имени":"Отсортировать по id"}</span></buuton>*/}
                </div>
                {showLoader?<LoaderIcon type={"bars"} color={"blue"} size={100} />:<PartnerTable tableData={partnerTableData}/>}
                {/*{showForm && <PartnerFrom changeShowForm={changeShowForm} addNewOffer={addNewOffer}/>}*/}
                <NotificationContainer/>
            </Container>
        </div>
    )
}

export default PartnerNetwork;