import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {NotificationContainer, NotificationManager} from "react-notifications";
import css from "./MyOffers.module.css";
import Container from "../Container/Container";
import LoaderIcon from "react-loader-icon";
import MyOffersTable from "./MyOfferTable/MyOffersTable";
import {FaNotesMedical} from "react-icons/fa";
import {BsFillPeopleFill} from "react-icons/bs";
import {FaComments} from "react-icons/fa6";
import host from "../../config/config";

function MyOffers() {
    const [showForm, setShowForm] = useState(false)
    const [offerData,setOfferData] = useState([])
    const [showLoader,setShowLoader] = useState(true)
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [selectedState, setSelectedState] = useState("All");
    const [selectedCapType, setSelectedCapType] = useState("All");
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(100);

    // const [fromDate, setFromDate] = useState("");
    // const [toDate, setToDate] = useState("");
    const navigate = useNavigate()


    const apiUrl = `${host}api/v1/buyer/my_offers/`;

    const token = localStorage.getItem('accessTokenLocal');

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            console.log(res.data)
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            console.log(e)
        })
    }


    useEffect(()=>{
        if(localStorage.getItem('userRole') === 'head_of_media_buying'){
            navigate('/partnerNetwork')
        }
        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };
        axios.get(apiUrl,config)
            .then((response) => {
                const offerS = response.data;
                setOfferData([...offerS])
                setFilteredData([...offerS]);
                setShowLoader(false)
            })
            .catch((error) => {
                refreshTokenData()
                NotificationManager.warning('Проблемы с сервером', 'Попробуйте позже', 3000);
                console.error('Помилка при виконанні GET-запиту:', error);
            });
    },[])




    const refreshData = () =>{
        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };
        axios.get(apiUrl,config)
            .then((response) => {
                const offerS = response.data;
                setOfferData([...offerS])
                setFilteredData([...offerS]);
                setShowLoader(false)
            })
            .catch((error) => {
                refreshTokenData()
                NotificationManager.warning('Проблемы с сервером', 'Попробуйте позже', 3000);
            });
    }

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        const filtered = offerData.filter(
            (item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (selectedCapType === "All" ||
                    (item.cap && item.cap.type.name === selectedCapType)) &&
                (selectedState === "All" || item.state === selectedState)
        );
        setFilteredData(filtered);
    };

    const handleCapTypeChange = (event) => {
        const selectedType = event.target.value;
        setSelectedCapType(selectedType);
        const filtered = offerData.filter(
            (item) =>
                (selectedType === "All" || (item.cap && item.cap.type.name === selectedType)) &&
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (selectedState === "All" || item.state === selectedState)
        );
        setFilteredData(filtered);
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setSelectedState(selectedState);

        const filtered = offerData.filter(
            (item) =>
                (selectedState === "All" || item.state === selectedState) &&
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (selectedCapType === "All" ||
                    (item.cap && item.cap.type.name === selectedCapType))
        );
        setFilteredData(filtered);
    };

    // const changeShowForm = ()=>{
    //     setShowForm(show => !show)
    // }
    // const addNewOffer = (offer) => {
    //     setOfferData(data => {
    //         return [
    //             ...data,
    //             offer
    //         ]
    //     })
    // }

    // const filterDataByDate = () => {
    //     const filtered = offerData.filter((item) =>
    //         item.caps_history.some((cap) => {
    //             const capDate = new Date(cap.created_at).toLocaleDateString();
    //             return (!fromDate || capDate >= fromDate) && (!toDate || capDate <= toDate);
    //         })
    //     );
    //     setFilteredData(filtered);
    // };

    // const filterDataByDate = () => {
    //     const filtered = offerData.filter((item) => {
    //         console.log(item);
    //         const capsHistory = item.caps_history;
    //
    //
    //         if (capsHistory && capsHistory.length > 0) {
    //             const capDate = capsHistory[capsHistory.length - 1].created_at;
    //             const secondsCap = Date.parse(capDate);
    //             // console.log("cap date ",secondsCap)
    //             // console.log("from date ",Date.parse(fromDate))
    //             // console.log("to date ", Date.parse(toDate))
    //             // console.log(secondsCap >= Date.parse(fromDate) && secondsCap <= Date.parse(toDate))
    //             return secondsCap >=  Date.parse(fromDate) && secondsCap <= Date.parse(toDate);
    //         }
    //
    //
    //         return true;
    //     });
    //
    //     setFilteredData(filtered);
    // };

    // const handleFromDateChange = (event) => {
    //     const newFromDate = event.target.value;
    //     setFromDate(newFromDate)
    // };

    // const handleToDateChange = (event) => {
    //     const newToDate = event.target.value;
    //     setToDate(newToDate)
    // };
    //
    //
    //
    // useEffect(() => {
    //     if(fromDate && toDate){
    //         filterDataByDate();
    //     }
    // }, [fromDate, toDate, offerData]);

    const sortByName = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            const nameA = a.name.replace(/\[|\]/g, "").toLowerCase().trim();
            const nameB = b.name.replace(/\[|\]/g, "").toLowerCase().trim();

            if (nameA < nameB) {
                return sortOrder === "asc" ? -1 : 1;
            }
            if (nameA > nameB) {
                return sortOrder === "asc" ? 1 : -1;
            }
            return 0;
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle the sorting order
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return(
        <div className={css.offerSection}>
            <Container>
                {
                    !showForm &&  <div className={css.offerTopWrap}>
                        <div className={css.offerTitle}>
                            <h2 className={css.title}>Мои оффера</h2>
                        </div>
                        {/*<button onClick={()=>changeShowForm()}  className={css.addOfferBtn}>*/}
                        {/*    <CIcon icon={cilChartLine} width="15" style={{'--ci-primary-color': 'white'}} />*/}
                        {/*    <span  className={css.btnTitle}>Добавить группу</span>*/}
                        {/*</button>*/}
                    </div>
                }

                {showLoader?<LoaderIcon type={"bars"} color={"blue"} size={100} />:<>
                    <div className={css.filterGroup}>
                        <input
                            className={css.inputName}
                            type="text"
                            placeholder="Search by name"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <label className={css.selectWrap}>
                            <span className={css.selectTitle}>Cap Type:</span>
                            <select
                                className={css.selectInput}
                                value={selectedCapType}
                                onChange={handleCapTypeChange}
                            >
                                <option value="All">All Caps</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Total">Total</option>
                            </select></label>

                        <label className={css.selectWrap}>
                            <span className={css.selectTitle}>Статус</span>
                            <select
                                className={css.selectInput}
                                value={selectedState}
                                onChange={handleStateChange}
                            >
                                <option value="All">All</option>
                                <option value="active">Active</option>
                                <option value="cap_end">End Cap</option>
                                <option value="pause">Pause</option>
                            </select>
                        </label>
                        {/*    <label className={css.selectWrap}>*/}
                        {/*        <span className={css.selectTitle}>From Date:</span>*/}
                        {/*        <input*/}
                        {/*            type="date"*/}
                        {/*            value={fromDate}*/}
                        {/*            onChange={handleFromDateChange}*/}
                        {/*        />*/}
                        {/*    </label>*/}
                        {/*    <label className={css.selectWrap}>*/}
                        {/*        <span className={css.selectTitle}>To Date:</span>*/}
                        {/*        <input*/}
                        {/*            type="date"*/}
                        {/*            value={toDate}*/}
                        {/*            onChange={handleToDateChange}*/}
                        {/*        />*/}
                        {/*    </label>*/}
                        {/*    <button className={css.sortBtn} onClick={sortByName}>*/}
                        {/*        <img className={css.sortIcon} src={sortOrder === "asc" ? AZ : ZA} width={32} alt="Sort" />*/}
                        {/*    </button>*/}
                    </div>
                    <div className={css.iconWrap}>
                        <span><FaNotesMedical/>-Заметки</span>
                        <span><BsFillPeopleFill/>-Беера</span>
                        <span><FaComments/>-Отзывы</span>
                    </div>
                    <MyOffersTable sortOrder={sortOrder} sortByName={sortByName} refreshData={refreshData} offerData={currentItems}/>
                    <div>
                        <ul className={css.paginationList}>
                            {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }).map(
                                (_, index) => (
                                    <li key={index}>
                                        <button
                                            className={currentPage === index + 1 ?`${css.activePage} ${css.page}`  : css.page}
                                            onClick={() => paginate(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </>}
                {/*{*/}
                {/*showForm  && <OfferForm changeShowForm={changeShowForm} addNewOffer={addNewOffer}/>*/}
                {/*}*/}
                <NotificationContainer/>
            </Container>
        </div>
    )
}

export default MyOffers

