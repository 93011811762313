import css from "./CapModal.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import Buyers from "./Buyers/Buyers";
import host from "../../../../../config/config";

function CapModal(props) {
    const [selector,setSelector] = useState([])
    const [dataCapSize,setDataCapSize] = useState(0)
    const [dataTypeCap,setDataTypeCap] = useState()
    const [showBuyers , setShowBuyers] =useState(false)
    const [buyersList, setBuyersList] = useState([])


    const getSelectorsApi = `${host}api/v1/caps/types`
    const url = `${host}api/v1/offers_group/caps/add/`;

    const token = localStorage.getItem('accessTokenLocal');

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            console.log(res.data)
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            console.log(e)
        })
    }


    useEffect(()=>{
        setDataTypeCap(props.item.cap?.type?.pk || '')
        if(props.item.cap?.type?.pk || ''){
            setShowBuyers(true)
        }
        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };
        axios.get(getSelectorsApi,config).then(response=>{
            const types = response.data;
            setSelector([...types])
            setDataTypeCap(types[0].pk)
            setDataTypeCap(props.item.cap?.type?.pk || '')
            }
        ).catch(err=>{
            refreshTokenData()
            console.log(err)
        })
        console.log(props.item)
    },[])

  const  calculateTotalSize = (orders) => {
        let totalSize = 0;

        for (const order of orders) {
            const size = parseInt(order.size, 10);
            if (!isNaN(size)) {
                totalSize += size;
            }
        }

        console.log(totalSize)

        return totalSize;
    }

    const onChangeOrders = (orders) => {
        setBuyersList([...orders]);
    }

    const onFormChange = (e) =>{
        switch (e.target.name){
            case "selector":
                setShowBuyers(false)
                if(e.target.value == "4"){
                    setShowBuyers(true)
                }
                if(e.target.value !=="4"){
                    setBuyersList([])
                }
                setDataTypeCap(e.target.value)
                break
            case "capSize":
                setDataCapSize(+e.target.value)
                break
            default:
                break
        }
    }

    const onSubmitForm = (e) =>{
        e.preventDefault()

        if(buyersList.length !== 0){
            const totalSize = calculateTotalSize(buyersList);
            if(totalSize > dataCapSize){
                NotificationManager.warning('Проблемы с отправкой', 'Неправильно вводимые значения size', 3000);
                return
            }
        }
        const data = {
            group_id: props.item.group_id,
            cap_size:+dataCapSize,
            cap_type_id: +dataTypeCap,
            notify_all: props.notification,
            per_buyer_cups: [...buyersList]
        }

        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };

        axios.post(url, data,config)
            .then(response => {
                setSelector([])
                setDataCapSize(0)
                setDataTypeCap()
                setBuyersList([])
                props.refreshData()
                props.changeVisibleCap()
            })
            .catch(error => {
                NotificationManager.warning('Проюлема с отправкой', 'Проверьте написанные данные', 3000);
                refreshTokenData()
                console.error('Помилка POST-запиту:', error);
            });
    }

    return(
        <form className={css.capForm} onSubmit={onSubmitForm} onChange={onFormChange}>
            <label className={css.inputWrap}>
                <span className={css.inputSpan}>Cap size</span>
                <input className={css.input} name="capSize" type="number" placeholder="Cap size"/>
            </label>
            <label className={css.inputWrap}>
                <span className={css.inputSpan}>Cap type</span>
                <select value={dataTypeCap} name="selector" id="cap_name" className={css.selectFilter}>
                    {
                        selector.map(el=>{
                            return <option  value={el.pk}>{el.name}</option>
                        })
                    }
                </select>
            </label>
                <button className={css.capButton} type='submit'>{props.item.cap ? "Обновить" : "Добавить"}</button>
            {showBuyers && <Buyers item={props.item} onChangeOrders={onChangeOrders}/>}
        </form>
    )
}

export default CapModal