import Container from "../Container/Container";
import logo from "../../img/logo.png"
import userPng from "../../img/user.png"
import notificationImg from "../../img/bell.png"
import css from "./Header.module.css"
import {NavLink} from "react-router-dom";
import {useState} from "react";
import {CButton, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import ManualRefresh from "../ManualRefresh/ManualRefresh";

function Header(props) {
    // const [notification, setNotification] = useState(false);
    const [visibleLogin, setLogin] = useState(false)

    const onSubmitLogin = (e) =>{
        e.preventDefault()

        props.onSubmitLogin()
        setLogin(false)
    }
    return(
        <header className={css.headerSection}>
            <Container>
               <div className={css.headerContainer}>
                   <div className={css.logoWrap}>
                       <img width="65" src={logo} alt="logo" className={css.logo}/>
                       <ManualRefresh/>
                   </div>
                   <ul className={css.linkList} >
                       {/*<NavLink className= {({ isActive}) => isActive ? css.active : css.link}*/}
                       {/*         to="/statistics">Статистика</NavLink>*/}
                       {localStorage.getItem('userRole')!== `buyer`?<NavLink  className= {({ isActive}) => isActive ? css.active : css.link}
                           to="/">Оффера</NavLink>:<NavLink  className= {({ isActive}) => isActive ? css.active : css.link}
                                                             to="/buyerOffers">Оффера</NavLink>}
                       {localStorage.getItem('userRole')!== 'buyer'?<NavLink  className= {({ isActive}) => isActive ? css.active : css.link}
                                                                              to="/partnerNetwork">Партнерские сети</NavLink>:<NavLink  className= {({ isActive}) => isActive ? css.active : css.link}
                                                                                                                                        to="/myOffers">Мои оффера</NavLink>}
                   </ul>
                   {props.showLogin?<button  className={css.exitBtn} onClick={props.onExit}>Выйти</button>:<CButton className={css.logBtn}   onClick={() => setLogin(!visibleLogin)}>Войти</CButton>}
                   {/*<div className={css.userWrap}>*/}
                   {/*    <div className={css.userNotification}>*/}
                   {/*        <img width="30" src={notificationImg} alt="notification"/>*/}
                   {/*        <div className={notification ? css.avtiveNotificationPoint :css.notificationPoint}></div>*/}
                   {/*    </div>*/}
                   {/*    <img className={css.userImg} width="30" src={userPng} alt="user"/>*/}
                   {/*</div>*/}
                   <CModal
                       fullscreen="sm"
                       visible={visibleLogin}
                       onClose={() => setLogin(false)}
                       aria-labelledby="FullscreenExample2"
                   >
                       <CModalHeader>
                           <CModalTitle id="FullscreenExample2">Login</CModalTitle>
                       </CModalHeader>
                       <CModalBody>
                            <form onChange={props.onChangeForm} onSubmit={onSubmitLogin} className={css.fromWrap}>
                                <label className={css.loginInputWrap}>
                                    <span className={css.loginText}>Username</span>
                                    <input value={props.userName} name="login" className={css.loginInput} placeholder="Username" type="text"/>
                                </label>
                                <label className={css.loginInputWrap}>
                                    <span className={css.loginText}>Password</span>
                                    <input value={props.password} name="password" className={css.loginInput} placeholder="Password" type="password"/>
                                </label>
                                <button type="submit" className={css.loginBtn}>Войти</button>
                            </form>
                       </CModalBody>
                   </CModal>
               </div>
            </Container>
        </header>
    )
}

export default Header