import Container from "../Container/Container";
import css from "./Statistics.module.css";
import CIcon from "@coreui/icons-react";
import {cilChartLine} from "@coreui/icons";
import OfferTable from "../Offer/OfferTable/OfferTable";
import OfferForm from "../Offer/OfferForm/OfferForm";
import StatisticsTable from "./StatisticsTable/StatisticsTable";
import {useState} from "react";
import {nanoid} from "nanoid";

function Statistics() {
    const [statisticsData,setStatisticsData] = useState([
        {
            id: nanoid(10),
            companyName: "Roel",
            offer:"Reol",
            clickCount: 15,
            conversionsCount:10
        },
        {
            id: nanoid(10),
            companyName: "Bods",
            offer:"Daet",
            clickCount: 23,
            conversionsCount:4
        }
    ])

    return(
        <div className={css.offerSection}>
            <Container>
                    <div className={css.offerTopWrap}>
                        <div className={css.offerTitle}>
                            <h2 className={css.title}>Статистика</h2>
                            <p className={css.subTitle}>Список статистики</p>
                        </div>
                        {/*<button  className={css.addOfferBtn}>*/}
                        {/*    <CIcon icon={cilChartLine} width="15" style={{'--ci-primary-color': 'white'}} />*/}
                        {/*    <span  className={css.btnTitle}>Добавить группу</span>*/}
                        {/*</button>*/}
                    </div>
                <StatisticsTable statisticsData={statisticsData}/>
            </Container>
        </div>
    )
}

export default Statistics;