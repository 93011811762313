import React, { useEffect, useState } from "react";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import css from './Buyers.module.css'
import { IoIosPersonAdd } from "react-icons/io";
import host from "../../../../../../config/config";

function Buyers(props) {
    const [buyers, setBuyers] = useState([]);
    const [selectedBuyer, setSelectedBuyer] = useState(null);
    const [size, setSize] = useState("");
    const [orders, setOrders] = useState([]);
    const [showSelectors, setShowSelectors] = useState(false)

    const url = `${host}api/v1/user/buyers`;

    const token = localStorage.getItem('accessTokenLocal');

    const refreshTokenData = () => {
        const refreshTokenUrl = `${host}api/token/refresh/`

        const refreshToken = localStorage.getItem("refreshTokenLocal")
        const tokenData = {
            refresh: refreshToken
        }

        axios.post(refreshTokenUrl,tokenData).then((res)=>{
            localStorage.setItem('accessTokenLocal',`JWT ${res.data.access}`)
            localStorage.setItem('refreshTokenLocal',res.data.refresh)
            window.location.reload(false);
        }).catch((e)=>{
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
            localStorage.setItem('accessTokenLocal','')
            localStorage.setItem('refreshTokenLocal','')
            NotificationManager.warning('Проблемы с входом', 'Авторизируйтесь в аккаунт', 3000);
        })
    }

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': `${token}`
            }
        };
        axios
            .get(url,config)
            .then((response) => {
                console.log("Успішно отримано дані:", response.data);
                setBuyers([...response.data]);
            })
            .catch((error) => {
                refreshTokenData()
                console.error("Помилка запиту:", error);
            });
        console.log(props.item)
    }, []);

    useEffect(()=>{
        props.onChangeOrders(orders)
    },[orders])

    const handleBuyerChange = (event) => {
        const selectedTelegramId = event.target.value;
        setSelectedBuyer(selectedTelegramId);
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleAddOrder = () => {
        if (selectedBuyer && size) {
            const existingBuyer = orders.find((orders) => orders.telegram_id == selectedBuyer);
            if (existingBuyer) {
                const updatedOrders = orders.map((orders) =>
                    orders.telegram_id == selectedBuyer
                        ? { ...orders, size: +size }
                        : orders
                );
                setOrders(updatedOrders);
            } else {
                const newOrder = {
                    telegram_id: +selectedBuyer,
                    size: +size,
                };
                setOrders([...orders, newOrder]);
            }
            setSize("");
        }
        console.log(orders);
    };




    return (
        <div className={css.buyersSection}>
            <div className={css.selectWrap}>
                {showSelectors && <select className={css.selectorInput} onChange={handleBuyerChange}>
                    <option value="">Выберите байера</option>
                    {buyers.map((buyer) => (
                        <option key={buyer.telegram_id} value={buyer.telegram_id}>
                            {buyer.telegram_username}
                        </option>

                    ))}
                        </select>
                }

                {selectedBuyer && (
                    <div className={css.wrapSizeInput}>
                        <input
                            className={css.inputSize}
                            type="text"
                            placeholder="Введите size"
                            value={size}
                            onChange={handleSizeChange}
                        />
                        <button className={css.addBtn} type={"button"} onClick={handleAddOrder}>Добавить</button>
                    </div>
                )}
            </div>
            <div>
                {props.item.cap?.per_buyer_caps?.length !== 0 ? (
                   <>
                       <h2>Баера:<IoIosPersonAdd className={css.iconAddBuyer} onClick={()=>{setShowSelectors(!showSelectors)}} /></h2>
                       <ul className={css.buyerList}>
                           {props.item.cap?.per_buyer_caps?.map((el,index)=>{
                               return <li className={css.allreadyBuyyerItem} key={index}>
                                 <p>{el.buyer_name}</p>
                                   <p>    <b>Розмір</b>: {el.per_size}</p>
                               </li>
                           })}
                       </ul>
                   </>
                ):(<><h2>Баера:<IoIosPersonAdd className={css.iconAddBuyer} onClick={()=>{setShowSelectors(!showSelectors)}} /></h2>
                    <p>Баеров пока нет</p>
                </>)}
            </div>
            <div className={css.buyersBox}>
                {showSelectors && <><h2>Обновленные баера:</h2>
                    <ul className={css.buyerList}>
                {orders.map((order, index) => (
                    <li key={index}>
                        <b>UserName</b>:   {buyers.map(el=>{
                        if(el.telegram_id == order.telegram_id){
                            return el.telegram_username
                        }
                    })},  <b>Розмір</b>: {order.size}
                    </li>
                ))}
            </ul>
                 </>
                }
            </div>
        </div>
    );
}

export default Buyers;
